.rainbow-button {
    background: linear-gradient(
      to right,
      red,
      rgb(144, 95, 2),
      rgb(144, 144, 1),
      green,
      blue,
      indigo,
      rgb(109, 56, 109),
      red
    );
    background-size: 200% auto;
    color: white;
    border: none;
    transition: background 0.5s;
    width: 100%;
    font-size: 16px; /* Increase font size for better visibility */
    animation: rainbow-motion 5s linear infinite; /* Add animation */
  }
  
  .rainbow-button:hover {
    background-position: right center; /* Animate the rainbow background on hover */
    color: white;
    text-decoration: none;
    animation-play-state: paused; /* Pause animation on hover */
  }
  
  .rainbow-button:disabled {
    background: linear-gradient(
      to right,
      darkred,
      darkorange,
      darkgoldenrod,
      darkgreen,
      darkblue,
      indigo,
      darkviolet
    );
    color: white;
    opacity: 0.4; /* Slightly reduce opacity to indicate disabled state */
    animation: none; /* Disable animation when button is disabled */
  }

  @keyframes rainbow-motion {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 200% 50%;
    }
  }

  .image-container {
    position: relative;
    display: inline-block;
  }
  
  .image-container img {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 40%; /* Adjust the size of the overlay as needed */
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
    transform-origin: center; /* Ensure enlargement is centered */
    z-index: 100;
  }

  .overlay-image:hover {
    transform: translate(-50%, -50%) scale(2); /* Enlarge the image on hover */
    z-index: 100;
  }

#imageTextArea {
  background: linear-gradient(
    to right,
    rgba(255, 0, 0, 0.1),
    rgba(255, 165, 0, 0.1),
    rgba(255, 255, 0, 0.1),
    rgba(0, 128, 0, 0.1),
    rgba(0, 0, 255, 0.1),
    rgba(75, 0, 130, 0.1),
    rgba(238, 130, 238, 0.1)
  );
  background-size: 200% auto;
  transition: background-position 0.5s;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  color: #333;
}

#imageTextArea:focus {
  background-position: right center;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.placeholder-text-container {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    max-width: 300px;
    z-index: 2;
}

.placeholder-text {
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 0.9em;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
